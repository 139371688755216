.welcome {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  &-particles {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-message {
    text-align: center;
    font-family: 'Oxygen', sans-serif;
  }
}

@media (max-width: 768px) {
  .welcome-message {
    font-size: 1.2rem;
    padding: 3.5rem;

    & h2 {
      font-size: 1.2rem;
    }
  }
}