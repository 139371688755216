.logo {
  height: 6rem;
  margin: 0 5rem;
}

@media (max-width: 768px) {
  .logo {
    height: 4rem;
    margin: 0 2rem;
  }
}
