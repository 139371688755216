.showcase {
  display: flex;
  flex-direction: column;
  padding: 6rem 5rem 2rem 5rem;

  & h2 {
    text-align: center;
    font-weight: 700;
    font-size: 4.4rem;
    width: 50%;
    margin: none;
    align-self: center;
  }

  & p {
    text-align: center;
    padding: 0 0 3rem 0;
    font-size: 2.2rem;
    font-family: 'Lato', sans-serif;
    align-self: center;
    margin-bottom: 3rem;
  }

  & img {
    width: 90%;
    height: 80%;
    align-self: center;
  }

  &-last-row {
    display: flex;
    flex-direction: row;
    height: 10rem;
    margin: 0.5rem 0 2rem 0;

    .tech-list {
      display: flex;
      margin: 2rem 0 1rem 8%;
      width: 55%;
      text-align: left;
      justify-content: flex-start;
      flex-wrap: wrap;

      & span {
        align-self: center;
        font-size: 1.8rem;
      }
    }

    .link-buttons {
      display: flex;
      width: 45%;
      align-items: center;
      justify-content: space-evenly;

      & button {
        border-radius: 2px;
        border: none;
        cursor: pointer;
        height: 4rem;
        width: 15rem;
        text-align: center;
        font-size: 1.5rem;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        transition: all 0.25s ease-in-out;

        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .showcase {
    padding: 2rem 2rem 0.5rem 2rem;

    & h2 {
      font-size: 1.8rem;
      width: 100%;
    }

    & p {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    & .tech-list {
      width: 60%;

      & span {
        font-size: 0.8rem;
      }
    }

    & .link-buttons {
      width: 40%;
      flex-direction: column;

      & button {
        height: 2rem;
        width: 7.5rem;
        font-size: 0.6rem;
      }
    }
  }
}