.nav-links {
  display: flex;
  justify-items: space-evenly;
  font-size: 2rem;
  align-self: center;
  justify-self: flex-end;
  margin: 0 2rem 0 auto;

  & a {
    font-weight: bold;
    color: #4d4d4d;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: #2f78a8;
    }
  }
}

.tooltip {
  background-color: #3a627c !important;
  color: #e3e3e3 !important;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
}