.tech {
  display: flex;
  flex-direction: row;
  width: 100%;

  & h1 {
    text-align: center;
    padding: 2rem 0;
    font-weight: bold;
    font-size: 3.8rem;
    width: 50%;
    margin: 0 auto;
  }

  &-waka {
    width: 50%;
    padding: 0 auto;
    background-color: #e3e3e3;

    & h1 {
      color: #1b1b1b;
      border-bottom: solid #1b1b1b;
    }

    & figure {
      margin: 5rem auto;
      padding: 1rem;
    }
  }

  &-stack {
    width: 50%;
    background-color: #a0a0a0;

    & h1 {
      color: #e3e3e3;
      border-bottom: solid #e3e3e3;
    }

    &-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 2rem auto;
      padding: 1rem 4rem;
    }

    &-icon {
      height: 8.5rem;
      width: 8.5rem;
      margin: 0.5rem 1rem;
    }
  }
}

@media (max-width: 768px) {
  .tech {
    flex-direction: column;

    & h1 {
      font-size: 2rem;
    }

    &-waka {
      width: 100%;
    }

    &-stack {
      width: 100%;

      &-list {
        padding: 0;
        margin: 2rem;
      }

      &-icon {
        height: 5rem;
        width: 5rem;
      }
    }
  }
}

// iPhone 5, 6, 7 non plus and X
@media (max-width: 376px) {
  .tech-stack-icon {
    height: 3.5rem;
    width: 3.5rem;
  }
}

// Samsung Galaxy 5
@media (max-width: 360px) {
  .tech-stack-list {
    margin: 2rem 1rem;
  }
}