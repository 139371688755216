.about {
  background-color: #1b1b1b;
  min-height: 75rem;
  width: 100%;
  color: #e3e3e3;

  & h1 {
    text-align: center;
    padding: 2rem 0;
    font-weight: bold;
    font-size: 4.4rem;
    width: 50%;
    margin: 0 auto;
    border-bottom: solid #e3e3e3;
  }

  & p {
    font-size: 26px;
    text-align: left;
    padding: 3rem 25rem 5rem 25rem;
  }
}

@media (max-width: 768px) {
  .about {
    & h1 {
      font-size: 2rem;
    }

    & p {
      font-size: 1.6rem;
      padding: 1rem 5rem 2rem 5rem;
    }
  }
}