.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  z-index: 1;
  justify-content: space-between;
}

// @media (max-width: 768px) {
//   .header {
//     justify-content: center;
//   }
// }
